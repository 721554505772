<template>
  <div>
    <h3 class="weight-6">
      {{ $t('file-tree') }}
    </h3>
    <BMessage
      v-if="error"
      type="is-danger"
      has-icon
      icon-size="is-small"
      size="is-small"
    >
      <h4>{{ $t('error') }}</h4>
      <p>{{ $t('unexpected-error-info-message') }}</p>
    </BMessage>
    <SlVueTree v-else v-model="nodes" :allow-multiselect="false">
      <template #toggle="{ node }">
        <template v-if="!node.isLeaf">
          <i
            :class="[
              'tree-toggle',
              'fas',
              node.isExpanded ? 'fa-angle-down' : 'fa-angle-right',
            ]"
          />
        </template>
      </template>

      <template #title="{ node }">
        <p :class="{ 'weight-6': node.data.id === file.id }">
          {{ node.title }}
        </p>
      </template>

      <template #sidebar="{ node }">
        <div class="flex gap-x-32 gap-y-8 align-center flex-wrap">
          <span>{{ filesize(node.data.size) }}</span>
          <UploadedFileStatus :file="node.data" :icon-only="true" />
          <div class="flex gap-8">
            <IdxBtn :href="node.data.downloadURL" color="primary" small>
              {{ $t('download') }}
            </IdxBtn>
            <IdxBtn color="danger" small @click="confirmDeletion(node.data)">
              {{ $t('delete') }}
            </IdxBtn>
          </div>
          <div>
            <IdxBtn
              v-if="node.data.thumbURL"
              class="color-blue"
              plain
              @click="samplePreview = node.data"
            >
              {{ $t('sample-preview') }}
            </IdxBtn>
            <span v-if="node.data.thumbURL && node.data.macroURL">
              &nbsp;/&nbsp;
            </span>
            <IdxBtn
              v-if="node.data.macroURL"
              class="color-blue"
              plain
              @click="slidePreview = node.data"
            >
              {{ $t('slide-preview') }}
            </IdxBtn>
          </div>
        </div>
      </template>
    </SlVueTree>

    <template v-if="samplePreview">
      <h4 class="flex gap-8 align-center my-2 border-b border-gray-2 pb-2">
        {{
          $t('sample-preview-of', { filename: samplePreview.originalFilename })
        }}
        <IdxBtn small @click="samplePreview = null">
          {{ $t('button-hide') }}
        </IdxBtn>
      </h4>
      <img :src="samplePreview.thumbURL" />
    </template>
    <template v-else-if="slidePreview">
      <h4 class="flex gap-8 align-center mb-2 border-b border-gray-2 pb-2">
        {{
          $t('slide-preview-of', { filename: slidePreview.originalFilename })
        }}
        <IdxBtn small @click="slidePreview = null">
          {{ $t('button-hide') }}
        </IdxBtn>
      </h4>
      <img :src="slidePreview.macroURL" />
    </template>
  </div>
</template>

<script>
import SlVueTree from 'sl-vue-tree';
import filesize from 'filesize';
import { UploadedFile, UploadedFileCollection } from 'cytomine-client';
import IdxBtn from '../global/IdxBtn.vue';
import UploadedFileStatus from './UploadedFileStatus.vue';

export default {
  name: 'UploadedFileDetails',
  components: {
    IdxBtn,
    SlVueTree,
    UploadedFileStatus,
  },
  props: {
    file: {
      type: Object,
      required: true, // WARNING: the root of the tree must be the file or its direct parent
    },
  },
  data() {
    return {
      rootId: null,
      uploadedFiles: [],
      nodes: [],
      slidePreview: null,
      samplePreview: null,
      error: false,
    };
  },
  watch: {
    file() {
      this.findRoot();
      this.makeTree();
    },
    slidePreview(val) {
      if (val) {
        this.samplePreview = null; // if slide preview enabled, disable sample preview
      }
    },
    samplePreview(val) {
      if (val) {
        this.slidePreview = null; // if sample preview enabled, disable slide preview
      }
    },
  },
  created() {
    this.findRoot();
    this.makeTree();
  },
  methods: {
    findRoot() {
      this.rootId = this.file.parentId || this.file.id;
    },
    async makeTree() {
      try {
        this.uploadedFiles = (
          await UploadedFileCollection.fetchAll({ root: this.rootId })
        ).array;
        this.nodes = this.createNodes(null);
      } catch (error) {
        console.log(error);
        this.error = true;
      }
    },
    createNodes(idParent) {
      const directChildren = this.uploadedFiles.filter(
        (file) => file.parentId === idParent
      );
      return directChildren.map((file) => {
        const children = this.createNodes(file.id);
        return {
          title: file.originalFilename,
          isLeaf: children.length === 0,
          isDraggable: false,
          isExpanded: true,
          data: {
            downloadURL: file.downloadURL,
            ...file,
          }, // data converted to object by sl-vue-tree => need to define downloadURL as property
          children,
        };
      });
    },
    filesize(size) {
      return filesize(size, { base: 10 });
    },
    confirmDeletion(file) {
      this.$buefy.dialog.confirm({
        title: this.$t('confirm-deletion'),
        message: this.$t('confirm-deletion-file'),
        type: 'is-danger',
        confirmText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        onConfirm: () => this.deleteFile(file),
      });
    },
    async deleteFile(file) {
      try {
        await UploadedFile.delete(file.id);
        this.$emit('update');
      } catch (error) {
        console.log(error);
        const errorValues = error.response.data.errorValues;
        let text = this.$t('notif-error-delete-uploaded-file');
        if (errorValues && errorValues.projectNames && errorValues.imageNames) {
          text = this.$t('notif-error-delete-used-uploaded-file', {
            projects: errorValues.projectNames.join(', '),
            names: errorValues.imageNames.join(', '),
          });
        }
        this.$notify({
          type: 'error',
          text,
        });
      }
    },
  },
};
</script>

<style scoped>
>>> .sl-vue-tree-sidebar {
  display: flex;
  align-items: top;
  justify-content: flex-end;
}

>>> .sl-vue-tree-gap {
  border: 0 dotted #bbb;
  border-left-width: 1px;
  position: relative;
  right: 0.95em;
  bottom: 1.25em;
}

>>> .sl-vue-tree-toggle {
  background: #fafafa;
  z-index: 1;
}

>>> .sl-vue-tree-gap:nth-last-child(3) {
  border-width: 0 0 1px 1px !important;
}
</style>
