export const UPLOADED_FILE_STATUS = {
  CONVERTED: 1,
  DEPLOYED: 2,
  ERROR_CONVERT: 4,
  ERROR_DEPLOYMENT: 8,
  ERROR_FORMAT: 3,
  TO_CONVERT: 7,
  TO_DEPLOY: 6,
  UNCOMPRESSED: 5,
  UPLOADED: 0,
};
