var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"weight-6"},[_vm._v(" "+_vm._s(_vm.$t('file-tree'))+" ")]),(_vm.error)?_c('BMessage',{attrs:{"type":"is-danger","has-icon":"","icon-size":"is-small","size":"is-small"}},[_c('h4',[_vm._v(_vm._s(_vm.$t('error')))]),_c('p',[_vm._v(_vm._s(_vm.$t('unexpected-error-info-message')))])]):_c('SlVueTree',{attrs:{"allow-multiselect":false},scopedSlots:_vm._u([{key:"toggle",fn:function(ref){
var node = ref.node;
return [(!node.isLeaf)?[_c('i',{class:[
            'tree-toggle',
            'fas',
            node.isExpanded ? 'fa-angle-down' : 'fa-angle-right' ]})]:_vm._e()]}},{key:"title",fn:function(ref){
          var node = ref.node;
return [_c('p',{class:{ 'weight-6': node.data.id === _vm.file.id }},[_vm._v(" "+_vm._s(node.title)+" ")])]}},{key:"sidebar",fn:function(ref){
          var node = ref.node;
return [_c('div',{staticClass:"flex gap-x-32 gap-y-8 align-center flex-wrap"},[_c('span',[_vm._v(_vm._s(_vm.filesize(node.data.size)))]),_c('UploadedFileStatus',{attrs:{"file":node.data,"icon-only":true}}),_c('div',{staticClass:"flex gap-8"},[_c('IdxBtn',{attrs:{"href":node.data.downloadURL,"color":"primary","small":""}},[_vm._v(" "+_vm._s(_vm.$t('download'))+" ")]),_c('IdxBtn',{attrs:{"color":"danger","small":""},on:{"click":function($event){return _vm.confirmDeletion(node.data)}}},[_vm._v(" "+_vm._s(_vm.$t('delete'))+" ")])],1),_c('div',[(node.data.thumbURL)?_c('IdxBtn',{staticClass:"color-blue",attrs:{"plain":""},on:{"click":function($event){_vm.samplePreview = node.data}}},[_vm._v(" "+_vm._s(_vm.$t('sample-preview'))+" ")]):_vm._e(),(node.data.thumbURL && node.data.macroURL)?_c('span',[_vm._v("  /  ")]):_vm._e(),(node.data.macroURL)?_c('IdxBtn',{staticClass:"color-blue",attrs:{"plain":""},on:{"click":function($event){_vm.slidePreview = node.data}}},[_vm._v(" "+_vm._s(_vm.$t('slide-preview'))+" ")]):_vm._e()],1)],1)]}}]),model:{value:(_vm.nodes),callback:function ($$v) {_vm.nodes=$$v},expression:"nodes"}}),(_vm.samplePreview)?[_c('h4',{staticClass:"flex gap-8 align-center my-2 border-b border-gray-2 pb-2"},[_vm._v(" "+_vm._s(_vm.$t('sample-preview-of', { filename: _vm.samplePreview.originalFilename }))+" "),_c('IdxBtn',{attrs:{"small":""},on:{"click":function($event){_vm.samplePreview = null}}},[_vm._v(" "+_vm._s(_vm.$t('button-hide'))+" ")])],1),_c('img',{attrs:{"src":_vm.samplePreview.thumbURL}})]:(_vm.slidePreview)?[_c('h4',{staticClass:"flex gap-8 align-center mb-2 border-b border-gray-2 pb-2"},[_vm._v(" "+_vm._s(_vm.$t('slide-preview-of', { filename: _vm.slidePreview.originalFilename }))+" "),_c('IdxBtn',{attrs:{"small":""},on:{"click":function($event){_vm.slidePreview = null}}},[_vm._v(" "+_vm._s(_vm.$t('button-hide'))+" ")])],1),_c('img',{attrs:{"src":_vm.slidePreview.macroURL}})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }