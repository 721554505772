<template>
  <div v-if="!iconOnly">
    <span v-if="!isConverted || !file.nbChildren" class="tag" :class="tagClass">
      {{ $t(labels[file.status]) }}
    </span>
    <div v-else class="tags has-addons">
      <span class="tag is-success">{{ $t('converted') }}</span>
      <span class="tag is-light">{{
        $tc('count-files', file.nbChildren, { count: file.nbChildren })
      }}</span>
    </div>
  </div>
  <span v-else :class="['icon', textClass]">
    <i :class="['fas', iconClass]" :title="$t(labels[file.status])" />
  </span>
</template>

<script>
import { UPLOADED_FILE_STATUS } from './index.js';

export default {
  name: 'UploadedFileStatus',
  props: {
    /** @type {import('vue').PropOptions<{ status: number, nbChildren: number }>} */
    file: {
      type: Object,
      required: true,
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    /** @returns {boolean} */
    isConverted() {
      return this.file.status === UPLOADED_FILE_STATUS.CONVERTED;
    },
    labels() {
      return {
        [UPLOADED_FILE_STATUS.UPLOADED]: 'uploaded',
        [UPLOADED_FILE_STATUS.CONVERTED]: 'converted',
        [UPLOADED_FILE_STATUS.DEPLOYED]: 'deployed',
        [UPLOADED_FILE_STATUS.ERROR_FORMAT]: 'error-format',
        [UPLOADED_FILE_STATUS.ERROR_CONVERT]: 'error-convert',
        [UPLOADED_FILE_STATUS.UNCOMPRESSED]: 'uncompressed',
        [UPLOADED_FILE_STATUS.TO_DEPLOY]: 'to-deploy',
        [UPLOADED_FILE_STATUS.TO_CONVERT]: 'to-convert',
        [UPLOADED_FILE_STATUS.ERROR_DEPLOYMENT]: 'error-deployment',
      };
    },
    result() {
      switch (this.file.status) {
        case UPLOADED_FILE_STATUS.UPLOADED:
        case UPLOADED_FILE_STATUS.TO_DEPLOY:
        case UPLOADED_FILE_STATUS.TO_CONVERT:
        case UPLOADED_FILE_STATUS.UNCOMPRESSED:
          return 'info';
        case UPLOADED_FILE_STATUS.CONVERTED:
        case UPLOADED_FILE_STATUS.DEPLOYED:
          return 'success';
        case UPLOADED_FILE_STATUS.ERROR_FORMAT:
        case UPLOADED_FILE_STATUS.ERROR_CONVERT:
        case UPLOADED_FILE_STATUS.ERROR_DEPLOYMENT:
          return 'danger';
        default:
          return '';
      }
    },
    /** @returns {string} */
    tagClass() {
      return 'is-' + this.result;
    },
    /** @returns {string} */
    textClass() {
      return 'has-text-' + this.result;
    },
    iconClass() {
      switch (this.result) {
        case 'info':
          return 'fa-spinner';
        case 'success':
          return 'fa-check-square';
        case 'danger':
          return 'fa-times-circle';
        default:
          return '';
      }
    },
  },
};
</script>

<style scoped>
.fas {
  font-size: 1.3em;
}
</style>
